<template>
  <div class="project">
    <header class="black-bg">
      <div class="container full">
        <router-link to="/">
          <img src="../assets/logo-melo.svg" alt=".melo" class="logo" />
        </router-link>

        <div class="col">
          <nav :class="menuIsOpen ? 'open' : ''">
            <a href="#apresentacao">Apresentação</a>
            <a href="#sobre">Sobre a melo</a>
            <a href="#cases">Cases</a>
            <a href="#contato">Contato</a>
          </nav>

          <a href="#contato" class="contato">
            <p>
              <span>Entre em contato</span>
              <img src="../assets/arrow-orange.svg" />
            </p>
          </a>

          <button
            class="menu-hamburger"
            :class="menuIsOpen ? 'open' : ''"
            @click="menuIsOpen = !menuIsOpen"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>

    <section class="top">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">Conteúdo <span>Agência Conexo</span></p>
          </div>
          <div class="col">
            <p>
              Através da agência Conexo, atendemos diversos clientes e, dentre eles, o Grupo Suburban. Essa empresa é consolidada no mercado e distribui vestuário para várias empresas brasileiras. Somos parte na produção de conteúdo para redes sociais, sites, endomarketing e etc.
            </p>
          </div>
        </div>
      </div>

      <div class="container date">
        <div class="row">
          <div class="col">
            <p>Local - São Paulo</p>
            <p>Ano - 2023</p>
          </div>
          <div class="col">
            <p>Vídeo & Design</p>
          </div>
        </div>
      </div>
    </section>

    <section class="image">
      <div class="container">
        <div class="img-wrapper">
          <img src="../assets/projects/conteudo-agencia-conexo/1.png" />
        </div>
      </div>
    </section>

    <section class="image">
      <div class="container">
        <div class="img-wrapper">
          <img src="../assets/projects/conteudo-agencia-conexo/2.png" />
        </div>
      </div>
    </section>

    <section class="text">
      <div class="container">
        <p>
          Somos parte fundamental na produção e desenvolvimento de peças para marcas do mercado brasileiro.
        </p>
      </div>
    </section>

    <section class="video">
      <div class="container">
        <div class="video-wrapper" :class="isVideoPlaying ? 'playing' : ''">
          <div class="video">
            <button class="pause-video" @click="pauseVideo()" v-if="isVideoPlaying"></button>
            <button class="play-video" @click="playVideo()" v-else>
              <img src="../assets/youtube.svg" class="mouse" />
            </button>
            <video
              ref="videoRef"
              src="../assets/projects/conteudo-agencia-conexo/video.mp4"
              type="video/mp4"
            ></video>
          </div>

          <div class="det">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </section>

    <section class="image">
      <div class="container">
        <div class="img-wrapper">
          <img src="../assets/projects/conteudo-agencia-conexo/3.png" />
        </div>
      </div>
    </section>

    <section class="back">
      <router-link to="/">
        <p>
          <img src="../assets/arrow-orange.svg" /> <span>Voltar para home</span>
        </p>
      </router-link>
    </section>

    <footer id="contato">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">Entre em <span>contato</span></p>
          </div>

          <div class="col">
            <a
              href="https://wa.me//5511954387151"
              target="_blank"
              class="contato"
            >
              <p>
                <span>Abrir WhatsApp</span>
                <img src="../assets/arrow-orange.svg" />
              </p>
            </a>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">Email</p>
            <div class="links">
              <a href="mailto:contato@meloproducoes.com.br">
                <img src="../assets/mail.svg" />
                <span> contato@meloproducoes.com.br </span>
              </a>
            </div>
          </div>

          <div class="col">
            <p class="title">Telefone</p>
            <div class="links">
              <a href="">
                <img src="../assets/phone.svg" />
                <span> (11) 95438-7151 </span>
              </a>
            </div>
          </div>

          <div class="col">
            <p class="title">Social</p>
            <div class="links">
              <a href="https://www.instagram.com/melo.prod" target="_blank">
                Instagram
              </a>

              <a
                href="https://www.facebook.com/meloprod?_rdc=1&_rdr"
                target="_blank"
              >
                Facebook
              </a>

              <a href="https://www.youtube.com/@melopontoprod" target="_blank">
                YouTube
              </a>

              <a
                href="https://www.linkedin.com/company/meloprod/"
                target="_blank"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ProjectView",

  components: {},

  beforeCreate() {},

  async beforeMount() {
    this.isPageLoading = true;
  },

  async mounted() {
    this.isPageLoading = false;
  },

  data() {
    return {
      isPageLoading: true,
      menuIsOpen: false,
      isVideoPlaying: false,
    };
  },

  watch: {},

  computed: {},

  methods: {
    playVideo() {
      var _this = this;
      this.$refs.videoRef.play();
      this.isVideoPlaying = true;
      this.$refs.videoRef.addEventListener("ended", function () {
        _this.isVideoPlaying = false;
      });
    },

    pauseVideo() {
      this.$refs.videoRef.pause();
      this.isVideoPlaying = false;
    }
  },
};
</script>

<style scoped lang="scss">
.project {
  position: relative;
  z-index: 0;
  margin-top: 130px;

  section {
    @media only screen and (max-width: 1024px) {
      padding: 0 20px;
    }
    &.top {
      .container {
        &:first-child {
          padding: 150px 0 72px 0;

          .row {
            @media only screen and (max-width: 768px) {
              flex-direction: column;
            }

            .col {
              width: 50%;
              
              @media only screen and (max-width: 768px) {
                width: 100%;
              }

              &:last-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                  max-width: 405px;
                  color: #0f0f0f;
                  font-family: Gotham;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 325;
                  line-height: 26px;
                }
              }
            }
          }
        }

        &.date {
          border-top: 2px solid #e0e0e0;
          padding: 50px 0 150px 0;

          .row {
            .col {
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &:first-child {
                p {
                  margin-right: 32px;
                }
              }

              &:last-child {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    &.image {
      .img-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        border-radius: 88px;
        overflow: hidden;

        margin-bottom: 50px;

        @media only screen and (max-width: 1024px) {
          border-radius: 44px;
        }

        @media only screen and (max-width: 425px) {
          border-radius: 22px;
        }

        img {
          width: 100%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &.video {
      margin-bottom: 50px;

      .video-wrapper {
        border-radius: 88px;

        @media only screen and (max-width: 1024px) {
          border-radius: 44px;
        }

        @media only screen and (max-width: 425px) {
          border-radius: 22px;
        }
      }
    }

    &.text {
      padding: 105px 0;

      p {
        width: 100%;
        max-width: 530px;
        color: #0f0f0f;
        text-align: center;
        font-family: Gotham;
        font-size: 18px;
        font-style: normal;
        font-weight: 325;
        line-height: 30px;
      }
    }

    &.back {
      padding: 50px 0;

      a {
        margin: auto;
        display: flex;

        p {
          color: #0f0f0f;
          font-family: Baguede;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }

        img {
          transform: rotate(-180deg);
          margin-right: 24px;
        }
      }
    }
  }

  footer {
    padding-top: 215px;
    padding-bottom: 160px;

    position: relative;

    .container {
      &:first-child {
        padding-bottom: 80px;

        .row {
          .col {
            width: 50%;

            p {
              &.title {
                color: #0f0f0f;
                font-family: Baguede;
                font-size: 112px;
                font-style: normal;
                font-weight: 400;
                line-height: 112px;

                @media only screen and (max-width: 768px) {
                  font-size: 85px;
                  line-height: 85px;
                }

                @media only screen and (max-width: 425px) {
                  font-size: 45px;
                  line-height: 45px;
                }

                span {
                  color: #ff5e29;
                  font-family: Baguede;
                  font-size: 112px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 112px;

                  @media only screen and (max-width: 768px) {
                    font-size: 85px;
                    line-height: 85px;
                  }

                  @media only screen and (max-width: 425px) {
                    font-size: 45px;
                    line-height: 45px;
                  }
                }
              }
            }

            &:last-child {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .contato {
                background: #ffffff;

                span {
                  color: #000000;
                  transition: all 0.3s ease;
                }

                &:hover {
                  span {
                    color: #ff4805;
                  }
                }
              }
            }
          }
        }
      }

      &:last-child {
        border-top: 1px solid #e0e0e0;
        padding-top: 80px;

        .row {
          @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
          }
          .col {
            @media only screen and (max-width: 1024px) {
              width: 100%;
              margin-bottom: 30px;
            }

            @media only screen and (max-width: 425px) {
              width: 100%;
              margin-bottom: 30px;
            }

            &:first-child,
            &:nth-child(2) {
              @media only screen and (max-width: 1024px) {
                width: 50%;
              }

              @media only screen and (max-width: 425px) {
                width: 100%;
                margin-bottom: 30px;
              }
            }

            .title {
              color: #0f0f0f;
              font-family: Baguede;
              font-size: 40px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .links {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media only screen and (max-width: 425px) {
                flex-wrap: wrap;
              }

              a {
                color: #787878;
                font-family: Gotham;
                font-size: 20px;
                font-style: normal;
                font-weight: 325;
                line-height: 30px;
                text-decoration: none;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                transition: all 0.3s ease;
                margin-right: 30px;

                @media only screen and (max-width: 425px) {
                  width: 50%;
                  margin-right: 0;
                }

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  color: #ff4805;
                }

                img {
                  width: 20px;
                  margin-right: 5px;
                }

                span {
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes scroll-arrow {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}
</style>