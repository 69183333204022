<template>
  <div class="home">
    <header
      :class="[isScroll ? 'black-bg' : '', isProjectPage ? 'black-bg' : '']"
    >
      <div class="container full">
        <router-link to="/">
          <img src="../assets/logo-melo.svg" alt=".melo" class="logo" />
        </router-link>

        <div class="col">
          <nav :class="menuIsOpen ? 'open' : ''">
            <a href="#apresentacao">Apresentação</a>
            <a href="#sobre">Sobre a melo</a>
            <a href="#cases">Cases</a>
            <a href="#contato">Contato</a>
          </nav>

          <a href="#contato" class="contato">
            <p>
              <span>Entre em contato</span>
              <img src="../assets/arrow-orange.svg" />
            </p>
          </a>

          <button
            class="menu-hamburger"
            :class="menuIsOpen ? 'open' : ''"
            @click="menuIsOpen = !menuIsOpen"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>

    <div class="banner">
      <div class="container">
        <p class="title">
          Cada frame <br />
          <span class="efeito">é uma</span><br />
          <span class="efeito-dois">oportunidade</span> <br />
          <span>para criar.</span>
        </p>
      </div>

      <div class="container">
        <div class="scroll">
          <img src="../assets/mouse.svg" class="mouse" />
          <img src="../assets/chevron-grey.svg" class="arrow" />
        </div>

        <p>
          Impulsionar empresas através do Design, entregando soluções criativas
          e funcionais, posicionando clientes à frente da concorrência, com
          transparência e segurança.
        </p>
      </div>

      <div class="det">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <section class="movendo" id="apresentacao">
      <div class="container">
        <p class="title left">Movendo <span>projetos.</span></p>

        <div class="video-wrapper" :class="isVideoPlaying ? 'playing' : ''">
          <div class="video">
            <button class="pause-video" @click="pauseVideo()" v-if="isVideoPlaying"></button>
            <button class="play-video" @click="playVideo()" v-else>
              <img src="../assets/youtube.svg" class="mouse" />
            </button>
            <video
              ref="videoRef"
              src="../assets/videos/showreel-melo-prod-2024.mp4"
              type="video/mp4"
            ></video>
          </div>

          <div class="det">
            <div></div>
            <div></div>
          </div>
        </div>

        <p class="title right">Animando <span>ideias.</span></p>
      </div>
    </section>

    <section class="sobre" id="sobre">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">
              Sobre <br />
              <span>a melo</span>
            </p>

            <a href="#contato" class="contato">
              <p>
                <span>Fale com a gente</span>
                <img src="../assets/arrow-orange.svg" />
              </p>
            </a>
          </div>

          <div class="col">
            <p>
              A Melo surge em 2019, suprindo uma demanda criativa.
              Logo evoluiu como estúdio de design e segue ampliando seu posicionamento e equipe. Em 2023, contamos com diversos profissionais capacitados e alinhados com a política da Melo, e seguimos buscando o crescimento.
            </p>

            <img src="../assets/efeito-2.svg" />
          </div>
        </div>
      </div>

      <div class="container gallery">
        <div class="row">
          <div class="col first">
            <div class="block">
              <p>Revista & Design</p>
              <button @click.prevent="$router.push('/evento-revista-e-design')">
                <span></span>
                <span></span>
              </button>
              <img src="../assets/gallery-1.png" />
            </div>
          </div>

          <div class="col second">
            <div class="block">
              <p>Cabanas do Morro</p>
              <button @click.prevent="$router.push('/conteudo-cabanas-do-morro')">
                <span></span>
                <span></span>
              </button>
              <img src="../assets/gallery-2.png" alt="" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col third">
            <div class="block">
              <p>Corrida do Bombeiro</p>
              <button @click.prevent="$router.push('/branding-corrida-do-bombeiro')">
                <span></span>
                <span></span>
              </button>
              <img src="../assets/gallery-3.png" />
            </div>
          </div>

          <div class="col fourth">
            <div class="block first">
              <p>Clínica Fakiani</p>
              <button @click.prevent="$router.push('/conteudo-clinica-fakiani')">
                <span></span>
                <span></span>
              </button>
              <img src="../assets/gallery-4.png" />
            </div>

            <div class="block second">
              <p>Wunder</p>
              <button @click.prevent="$router.push('/conteudo-agencia-conexo')">
                <span></span>
                <span></span>
              </button>
              <img src="../assets/gallery-5.png" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="clientes" id="cases">
      <div class="container">
        <p class="title">Clientes</p>

        <div class="clientes">
          <a href="#">
            <img src="../assets/cli-accurate.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-datamachina.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-cidadesp.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-fundabom.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-camara.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-gupy.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-keyaccess.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-kronan.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-ipvm.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-underdogs.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-great.jpg" />
          </a>

          <a href="#">
            <img src="../assets/cli-conexo.jpg" />
          </a>
        </div>
      </div>
    </section>

    <footer id="contato">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">Entre em <span>contato</span></p>
          </div>

          <div class="col">
            <a
              href="https://wa.me//5511954387151"
              target="_blank"
              class="contato"
            >
              <p>
                <span>Abrir WhatsApp</span>
                <img src="../assets/arrow-orange.svg" />
              </p>
            </a>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">Email</p>
            <div class="links">
              <a href="mailto:contato@meloproducoes.com.br">
                <img src="../assets/mail.svg" />
                <span> contato@meloproducoes.com.br </span>
              </a>
            </div>
          </div>

          <div class="col">
            <p class="title">Telefone</p>
            <div class="links">
              <a href="">
                <img src="../assets/phone.svg" />
                <span> (11) 95438-7151 </span>
              </a>
            </div>
          </div>

          <div class="col">
            <p class="title">Social</p>
            <div class="links">
              <a href="https://www.instagram.com/melo.prod" target="_blank">
                Instagram
              </a>

              <a
                href="https://www.facebook.com/meloprod?_rdc=1&_rdr"
                target="_blank"
              >
                Facebook
              </a>

              <a href="https://www.youtube.com/@melopontoprod" target="_blank">
                YouTube
              </a>

              <a
                href="https://www.linkedin.com/company/meloprod/"
                target="_blank"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",

  components: {},

  beforeCreate() {},

  async beforeMount() {
    this.isPageLoading = true;
  },

  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isPageLoading = false;
  },

  async beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    return {
      isPageLoading: true,
      isScroll: false,
      menuIsOpen: false,
      isVideoPlaying: false,
    };
  },

  watch: {},

  computed: {},

  methods: {
    handleScroll() {
      if (window.scrollY >= 50) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },

    playVideo() {
      var _this = this;
      this.$refs.videoRef.play();
      this.isVideoPlaying = true;
      this.$refs.videoRef.addEventListener("ended", function () {
        _this.isVideoPlaying = false;
      });
    },

    pauseVideo() {
      this.$refs.videoRef.pause();
      this.isVideoPlaying = false;
    }
  },
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  z-index: 0;
  .banner {
    position: relative;

    border-radius: 0px 0px 144px 0px;
    background: #0f0f0f;

    background-image: url("@/assets/black-bird.png");
    background-position: bottom right;
    background-repeat: no-repeat;

    padding-top: 280px;
    padding-bottom: 180px;

    .title {
      color: #fff;
      font-family: Baguede;
      font-size: 112px;
      font-style: normal;
      font-weight: 400;
      line-height: 112px;

      @media only screen and (max-width: 1024px) {
        font-size: 85px;
        line-height: 85px;
      }

      @media only screen and (max-width: 425px) {
        font-size: 45px;
        line-height: 45px;
      }

      span {
        color: #ff5e29;

        &.efeito {
          position: relative;

          &:before {
            content: "";

            position: absolute;
            right: -60px;
            top: 35%;

            width: 35px;
            height: 60px;
            background-image: url("@/assets/efeito-1.svg");
            background-repeat: no-repeat;
            display: block;

            @media only screen and (max-width: 1024px) {
              right: -40px;
              top: 15%;

              width: 20px;
              height: 45px;

              background-size: contain;
            }
          }
        }

        &.efeito-dois {
          color: #ffffff;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: -65px;
            right: -60px;
            width: 145px !important;
            height: 145px;
            display: block;
            background-image: url("../assets/estudio-de-design.svg");
            background-repeat: no-repeat;
            background-position: center center;

            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }
        }
      }
    }

    .container {
      &:nth-child(2) {
        margin-top: 190px;
        .scroll {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          @media only screen and (max-width: 425px) {
            display: none;
          }

          .arrow {
            animation-name: scroll-arrow;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
        }

        p {
          color: #b0b0b0;
          font-family: Gotham;
          font-size: 18px;
          font-style: normal;
          font-weight: 325;
          line-height: 30px;

          max-width: 420px;
        }
      }
    }

    .det {
      position: absolute;
      left: 0;
      bottom: -32px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 425px) {
        transform: translateX(-200px);
      }

      div {
        width: 64px;
        height: 64px;
        background: #ff4805;
        border-radius: 32px;

        &:first-child {
          width: 330px;
          background: #ff6d37;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:last-child {
          transform: scaleX(-50%) translateX(50%);
          background: #ff9169;
        }
      }
    }
  }

  section {
    &.movendo {
      padding-top: 230px;
      padding-bottom: 200px;

      p {
        &:first-child {
          margin-bottom: 64px;
        }

        &:last-child {
          margin-top: 64px;
        }
      }
    }

    &.sobre {
      border-radius: 144px;
      background: #0f0f0f;
      padding-top: 224px;
      padding-bottom: 240px;

      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 425px) {
        border-radius: calc(144px / 3);
        padding-top: calc(224px / 3);
        padding-bottom: calc(240px / 3);
      }

      .container {
        &:first-child {
          .row {
            display: flex;
            align-items: flex-end;

            @media only screen and (max-width: 425px) {
              flex-wrap: wrap;
            }
            .col {
              width: 50%;

              @media only screen and (max-width: 425px) {
                width: 100%;
              }

              &:first-child {
                .title {
                  color: #fff;
                  font-family: Baguede;
                  font-size: 112px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 112px;

                  margin-bottom: 64px;

                  @media only screen and (max-width: 768px) {
                    font-size: 85px;
                    line-height: 85px;
                  }

                  @media only screen and (max-width: 425px) {
                    font-size: 45px;
                    line-height: 45px;
                  }

                  span {
                    color: #ff9169;
                    font-family: Baguede;
                    font-size: 112px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 112px;

                    @media only screen and (max-width: 768px) {
                      font-size: 85px;
                      line-height: 85px;
                    }

                    @media only screen and (max-width: 425px) {
                      font-size: 45px;
                      line-height: 45px;
                    }
                  }
                }
              }

              &:last-child {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-direction: column;

                p {
                  color: #b0b0b0;
                  font-family: Gotham;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 325;
                  line-height: 30px;
                  max-width: 530px;

                  margin-bottom: 140px;
                }

                img {
                  margin-bottom: 22px;
                }
              }
            }
          }
        }

        &.gallery {
          margin-top: 215px;

          .row {
            @media only screen and (max-width: 425px) {
              flex-wrap: wrap;
            }
            .col {
              @media only screen and (max-width: 425px) {
                width: 100% !important;
              }

              &.first {
                width: 55%;
              }

              &.second {
                width: 45%;
              }

              &.third {
                width: 30%;

                .block {
                  height: 705px;
                }
              }

              &.fourth {
                width: 70%;

                .block {
                  &:nth-child(1) {
                    margin-bottom: 20px;
                  }

                  &:nth-child(2) {
                    width: calc(100% + 120px);

                    @media only screen and (max-width: 1024px) {
                      width: unset;
                    }
                  }
                }
              }

              .block {
                position: relative;
                border-radius: 56px;
                height: 340px;
                overflow: hidden;
                margin: 10px;

                &:before {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  z-index: 2;
                  width: 100%;
                  height: 100%;
                  display: block;
                  opacity: 0;
                  transition: all 2s ease;

                  background: linear-gradient(
                    360deg,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 0) 100%
                  );
                }

                @media only screen and (max-width: 425px) {
                  height: 220px;
                }

                p {
                  color: #fff;
                  font-family: Gotham;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 325;
                  line-height: 30px;

                  position: absolute;
                  bottom: 48px;
                  left: 48px;
                  z-index: 2;

                  transform: translateY(50px);
                  opacity: 0;
                  transition: all 0.3s ease;
                }

                button {
                  position: absolute;
                  bottom: 24px;
                  right: 24px;
                  z-index: 3;

                  width: 68px;
                  height: 68px;
                  background: #0f0f0f;
                  border: 1px solid #343434;
                  border-radius: 100%;

                  span {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    width: 20px;
                    height: 1px;

                    background: #ffffff;
                    transform: translate(-50%, -50%);

                    transition: all 0.3s ease;

                    &:last-child {
                      transform: translate(-50%, -50%) rotate(90deg);
                    }
                  }

                  img {
                    position: relative;
                    z-index: 0;
                  }
                }

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  z-index: 1;
                  transform: translate(-50%, -50%);

                  transition: all 1s ease;
                }

                &:hover {
                  &:before {
                    opacity: 1;
                  }
                  p {
                    transform: translateY(0px);
                    opacity: 1;
                  }
                  img {
                    transform: translate(-50%, -50%) scale(1.1) rotate(2deg);
                  }
                  button {
                    span {
                      background: #ff4805;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.clientes {
      display: flex;
      flex-direction: column;
      padding: 250px 0;

      p {
        &.title {
          display: table;
          margin: auto;
          text-align: center;

          color: #0f0f0f;
          font-family: Baguede;
          font-size: 112px;
          font-style: normal;
          font-weight: 400;
          line-height: 112px; /* 100% */

          margin-bottom: 150px;

          @media only screen and (max-width: 768px) {
            font-size: 85px;
            line-height: 85px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 45px;
            line-height: 45px;
          }
        }
      }

      .clientes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        a {
          width: calc(100% / 3 - 60px);
          margin: 30px;

          @media only screen and (max-width: 768px) {
            width: calc(50% - 60px);
          }

          @media only screen and (max-width: 425px) {
            width: calc(100% - 60px);
          }

          img {
            width: 100%;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  footer {
    border-radius: 144px 144px 0px 0px;
    background: #eee;

    padding-top: 215px;
    padding-bottom: 160px;

    position: relative;

    &:before {
      content: "";

      background-image: url("../assets/st.svg");
      background-size: cover;
      width: 280px;
      height: 230px;

      position: absolute;
      top: -100px;
      right: 20%;
    }

    .container {
      &:first-child {
        padding-bottom: 80px;

        .row {
          .col {
            width: 50%;

            p {
              &.title {
                color: #0f0f0f;
                font-family: Baguede;
                font-size: 112px;
                font-style: normal;
                font-weight: 400;
                line-height: 112px;

                @media only screen and (max-width: 768px) {
                  font-size: 85px;
                  line-height: 85px;
                }

                @media only screen and (max-width: 425px) {
                  font-size: 45px;
                  line-height: 45px;
                }

                span {
                  color: #ff5e29;
                  font-family: Baguede;
                  font-size: 112px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 112px;

                  @media only screen and (max-width: 768px) {
                    font-size: 85px;
                    line-height: 85px;
                  }

                  @media only screen and (max-width: 425px) {
                    font-size: 45px;
                    line-height: 45px;
                  }
                }
              }
            }

            &:last-child {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .contato {
                background: #ffffff;

                span {
                  color: #000000;
                  transition: all 0.3s ease;
                }

                &:hover {
                  span {
                    color: #ff4805;
                  }
                }
              }
            }
          }
        }
      }

      &:last-child {
        border-top: 1px solid #e0e0e0;
        padding-top: 80px;

        .row {
          @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
          }
          .col {
            @media only screen and (max-width: 1024px) {
              width: 100%;
              margin-bottom: 30px;
            }

            @media only screen and (max-width: 425px) {
              width: 100%;
              margin-bottom: 30px;
            }

            &:first-child,
            &:nth-child(2) {
              @media only screen and (max-width: 1024px) {
                width: 50%;
              }

              @media only screen and (max-width: 425px) {
                width: 100%;
                margin-bottom: 30px;
              }
            }

            .title {
              color: #0f0f0f;
              font-family: Baguede;
              font-size: 40px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .links {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media only screen and (max-width: 425px) {
                flex-wrap: wrap;
              }

              a {
                color: #787878;
                font-family: Gotham;
                font-size: 20px;
                font-style: normal;
                font-weight: 325;
                line-height: 30px;
                text-decoration: none;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                transition: all 0.3s ease;
                margin-right: 30px;

                @media only screen and (max-width: 425px) {
                  width: 50%;
                  margin-right: 0;
                }

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  color: #ff4805;
                }

                img {
                  width: 20px;
                  margin-right: 5px;
                }

                span {
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes scroll-arrow {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}
</style>