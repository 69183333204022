/*************************************************************************************************************
 * Autor   : Danilo Targas
 * Data    : 12/12/2023
**************************************************************************************************************/

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './main.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
