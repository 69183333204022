import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EventoRevistaDesignView from '../views/EventoRevistaDesignView.vue'
import ConteudoCabanasDoMorroView from '../views/ConteudoCabanasDoMorroView.vue'
import BrandingCorridaDoBombeiroView from '../views/BrandingCorridaDoBombeiroView.vue'
import ConteudoClinicaFakianiView from '../views/ConteudoClinicaFakianiView.vue'
import ConteudoAgenciaConexoView from '../views/ConteudoAgenciaConexoView.vue'
import ProjectSampleView from '../views/ProjectSampleView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/evento-revista-e-design',
    name: 'evento-revista-e-design',
    component: EventoRevistaDesignView
  },
  {
    path: '/conteudo-cabanas-do-morro',
    name: 'conteudo-cabanas-do-morro',
    component: ConteudoCabanasDoMorroView
  },
  {
    path: '/branding-corrida-do-bombeiro',
    name: 'branding-corrida-do-bombeiro',
    component: BrandingCorridaDoBombeiroView
  },
  {
    path: '/conteudo-clinica-fakiani',
    name: 'conteudo-clinica-fakiani',
    component: ConteudoClinicaFakianiView
  },
  {
    path: '/conteudo-agencia-conexo',
    name: 'conteudo-agencia-conexo',
    component: ConteudoAgenciaConexoView
  },
  {
    path: '/project-sample',
    name: 'project-sample',
    component: ProjectSampleView
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () { 
    //to, from, savedPosition
    return { x: 0, y: 0 }
  }
})

export default router
