var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project"},[_c('header',{staticClass:"black-bg"},[_c('div',{staticClass:"container full"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/logo-melo.svg"),"alt":".melo"}})]),_c('div',{staticClass:"col"},[_c('nav',{class:_vm.menuIsOpen ? 'open' : ''},[_c('a',{attrs:{"href":"#apresentacao"}},[_vm._v("Apresentação")]),_c('a',{attrs:{"href":"#sobre"}},[_vm._v("Sobre a melo")]),_c('a',{attrs:{"href":"#cases"}},[_vm._v("Cases")]),_c('a',{attrs:{"href":"#contato"}},[_vm._v("Contato")])]),_vm._m(0),_c('button',{staticClass:"menu-hamburger",class:_vm.menuIsOpen ? 'open' : '',on:{"click":function($event){_vm.menuIsOpen = !_vm.menuIsOpen}}},[_c('span'),_c('span'),_c('span')])])],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('section',{staticClass:"video"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"video-wrapper",class:_vm.isVideoPlaying ? 'playing' : ''},[_c('div',{staticClass:"video"},[(_vm.isVideoPlaying)?_c('button',{staticClass:"pause-video",on:{"click":function($event){return _vm.pauseVideo()}}}):_c('button',{staticClass:"play-video",on:{"click":function($event){return _vm.playVideo()}}},[_c('img',{staticClass:"mouse",attrs:{"src":require("../assets/youtube.svg")}})]),_c('video',{ref:"videoRef",attrs:{"src":require("../assets/projects/conteudo-clinica-fakiani/video.mp4"),"type":"video/mp4"}})]),_vm._m(5)])])]),_vm._m(6),_c('section',{staticClass:"back"},[_c('router-link',{attrs:{"to":"/"}},[_c('p',[_c('img',{attrs:{"src":require("../assets/arrow-orange.svg")}}),_vm._v(" "),_c('span',[_vm._v("Voltar para home")])])])],1),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"contato",attrs:{"href":"#contato"}},[_c('p',[_c('span',[_vm._v("Entre em contato")]),_c('img',{attrs:{"src":require("../assets/arrow-orange.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v("Conteúdo "),_c('span',[_vm._v("Clínica Fakiani")])])]),_c('div',{staticClass:"col"},[_c('p',[_vm._v(" Em 2023 fizemos uma parceria com a clínica de público AAA, que nos possibilitou criar diversos tipos de conteúdo e em grandes volumes por mês. ")])])])]),_c('div',{staticClass:"container date"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',[_vm._v("Local - São Paulo")]),_c('p',[_vm._v("Ano - 2023")])]),_c('div',{staticClass:"col"},[_c('p',[_vm._v("Photo & Video")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"image"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("../assets/projects/conteudo-clinica-fakiani/1.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"image"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("../assets/projects/conteudo-clinica-fakiani/2.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text"},[_c('div',{staticClass:"container"},[_c('p',[_vm._v(" Criamos peças únicas e criativas que impactam visualmente e transmitem um conceito. Nosso objetivo é impulsionar organizações por meio do design! ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"det"},[_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"image"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("../assets/projects/conteudo-clinica-fakiani/3.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{attrs:{"id":"contato"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v("Entre em "),_c('span',[_vm._v("contato")])])]),_c('div',{staticClass:"col"},[_c('a',{staticClass:"contato",attrs:{"href":"https://wa.me//5511954387151","target":"_blank"}},[_c('p',[_c('span',[_vm._v("Abrir WhatsApp")]),_c('img',{attrs:{"src":require("../assets/arrow-orange.svg")}})])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v("Email")]),_c('div',{staticClass:"links"},[_c('a',{attrs:{"href":"mailto:contato@meloproducoes.com.br"}},[_c('img',{attrs:{"src":require("../assets/mail.svg")}}),_c('span',[_vm._v(" contato@meloproducoes.com.br ")])])])]),_c('div',{staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v("Telefone")]),_c('div',{staticClass:"links"},[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("../assets/phone.svg")}}),_c('span',[_vm._v(" (11) 95438-7151 ")])])])]),_c('div',{staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v("Social")]),_c('div',{staticClass:"links"},[_c('a',{attrs:{"href":"https://www.instagram.com/melo.prod","target":"_blank"}},[_vm._v(" Instagram ")]),_c('a',{attrs:{"href":"https://www.facebook.com/meloprod?_rdc=1&_rdr","target":"_blank"}},[_vm._v(" Facebook ")]),_c('a',{attrs:{"href":"https://www.youtube.com/@melopontoprod","target":"_blank"}},[_vm._v(" YouTube ")]),_c('a',{attrs:{"href":"https://www.linkedin.com/company/meloprod/","target":"_blank"}},[_vm._v(" LinkedIn ")])])])])])])
}]

export { render, staticRenderFns }